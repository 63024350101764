<template>
  <b-row>
    <b-col cols="12">
      <b-card title="All Events">
        <loading
          :active="isLoading"
          loader="bars"
          color="#7367f0"
          :is-full-page="fullPage"
        />
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          style-class="vgt-table striped"
          :sort-options="{
            enabled: false,
          }"
          :total-rows="totalRecords"
          :is-loading.sync="isLoadingTable"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
            skipDiacritics: true,
          }"
          :pagination-options="{
            enabled: true,
            perPageDropdownEnabled: false,
            dropdownAllowAll: false,
          }"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field == 'action'">
              <b-button
                v-b-tooltip.hover.bottom="'Broadcast Event'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
                class="btn-icon"
                style="margin-right: 2px; margin-bottom: 2px;"
                @click="broadcastEvent(props.row.id, props.row.open_date, props.row.close_date)"
              >
                <feather-icon icon="Volume2Icon" />
              </b-button>
              <b-button
                v-if="props.row.is_assessment_broadcasted"
                v-b-tooltip.hover.bottom="'Broadcast Assessment'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
                class="btn-icon"
                style="margin-right: 2px; margin-bottom: 2px;"
                @click="broadcastAssessment(props.row.id, props.row.maximum_approval_date)"
              >
                <feather-icon icon="SpeakerIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.bottom="'Edit'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="btn-icon"
                style="margin-right: 2px; margin-bottom: 2px;"
                :to="{ name: 'edit-servolution-event', params: { id: props.row.id } }"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.bottom="'Ministry Setup'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                style="margin-right: 2px; margin-bottom: 2px;"
                :to="{ name: 'servolution-ministry-setup', params: { id: props.row.id } }"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.bottom="'Participants'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="btn-icon"
                style="margin-right: 2px; margin-bottom: 2px;"
                :to="{ name: 'servolution-participants', params: { id: props.row.id } }"
              >
                <feather-icon icon="UsersIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.bottom="'Statistics'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
                class="btn-icon"
                style="margin-right: 2px; margin-bottom: 2px;"
                :to="{ name: 'servolution-statistics', params: { id: props.row.id } }"
              >
                <feather-icon icon="PieChartIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.bottom="'Delete'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="btn-icon"
                style="margin-right: 2px; margin-bottom: 2px;"
                @click="deleteEvent(props.row.id, props.row.open_date)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </span>
            <span v-else-if="props.column.field == 'open_date'">
              {{ moment(props.row.open_date).format("dddd, DD MMMM YYYY") }}
            </span>
            <span v-else-if="props.column.field == 'close_date'">
              {{ moment(props.row.close_date).format("dddd, DD MMMM YYYY") }}
            </span>
            <span v-else-if="props.column.field == 'maximum_approval_date'">
              {{ moment(props.row.maximum_approval_date).format("dddd, DD MMMM YYYY") }}
            </span>
            <span v-else-if="props.column.field == 'people_position'">
              <span v-if="props.row.people_position === null && props.row.leader_position === null">-</span>
              <span v-else-if="props.row.people_position !== null">{{ props.row.people_position }}</span>
              <span v-else>{{ props.row.leader_position }}</span>
            </span>
            <span v-else-if="props.column.field == 'status'">
              <b-badge
                v-if="moment(moment().format('YYYY-MM-DD')).isSameOrAfter(moment(moment(props.row.open_date).format('YYYY-MM-DD'))) && moment(moment().format('YYYY-MM-DD')).isSameOrBefore(moment(moment(props.row.close_date).format('YYYY-MM-DD')))"
                variant="success"
              >
                Open
              </b-badge>
              <b-badge
                v-else
                variant="danger"
              >
                Close
              </b-badge>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BCard,
  BBadge,
  BRow,
  BCol,
  BButton,
  VBTooltip,
  // BDropdown,
  // BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import _ from 'lodash'
// import Cookies from 'js-cookie'
import * as moment from 'moment'
import CryptoJS from 'crypto-js'
import Loading from 'vue-loading-overlay'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { isUserLoggedIn } from '@/auth/utils'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BCard,
    VueGoodTable,
    BBadge,
    BRow,
    BCol,
    BButton,
    // BDropdown,
    // BDropdownItem,
    Loading,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      moment,
      isLoading: true,
      isLoadingTable: false,
      fullPage: false,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'GMS',
          field: 'church_name',
        },
        {
          label: 'Open Date',
          field: 'open_date',
        },
        {
          label: 'Close Date',
          field: 'close_date',
        },
        {
          label: 'Maximum Approval date',
          field: 'maximum_approval_date',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {
          searchTerm: '',
        },
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 0,
        perPage: 10,
      },
      searchTerm: '',
      isRendered: false,
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    broadcastEvent(id, openDate, closeDate) {
      // Show loading
      this.isLoading = true

      // Check if user has access
      if (!this.$can('read', 'servolution_event_broadcast')) {
        return this.$router.replace({ name: 'misc-not-authorized' })
      }

      // Execute
      if (moment(moment().format('YYYY-MM-DD')).isSameOrAfter(moment(moment(openDate).format('YYYY-MM-DD'))) && moment(moment().format('YYYY-MM-DD')).isSameOrBefore(moment(moment(closeDate).format('YYYY-MM-DD')))) {
        this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/events/broadcast/${id}`).then(() => {
          // Hide loading
          this.isLoading = false

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Broadcast successfully.',
            },
          })
        })
      } else {
        // Hide loading
        this.isLoading = false

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: 'This event has been closed.',
          },
        })
      }
    },
    broadcastAssessment(id, maximumApprovalDate) {
      // Show loading
      this.isLoading = true
      console.log(maximumApprovalDate)

      // Check if user has access
      if (!this.$can('read', 'servolution_assessment_broadcast')) {
        return this.$router.replace({ name: 'misc-not-authorized' })
      }

      // Execute
      if (moment(moment().format('YYYY-MM-DD')).isAfter(moment(moment(maximumApprovalDate).format('YYYY-MM-DD')))) {
        this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/assessment/broadcast/${id}`).then(response => {
          // Hide loading
          this.isLoading = false

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              variant: 'success',
              text: response.result,
            },
          })
        }).catch(() => {
          // Hide loading
          this.isLoading = false

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: 'Assessment has not done yet.',
            },
          })
        })
      } else {
        // Hide loading
        this.isLoading = false

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: 'Assessment has not done yet.',
          },
        })
      }
    },
    updateParams(newProps) {
      this.serverParams = Object.assign(this.serverParams, Object.assign(newProps))
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPerPage * (params.currentPage - 1) })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    onSearch: _.debounce(function (params) {
      this.isLoading = true
      this.updateParams({ columnFilters: params })
      this.loadItems()
      return false
    }, 350),
    loadItems() {
      // Set data
      const userData = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('userData'), process.env.VUE_APP_COOKIE_SECRET).toString(CryptoJS.enc.Utf8))
      // const ministries = userData[0].departments.map(elem => elem).join(',')
      const divisions = userData[0].divisions.map(elem => elem).join(',')
      const churches = userData[0].churches.map(elem => elem).join(',')

      // Get the data
      this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/events/${divisions}/${churches}?page=${this.serverParams.page}&perpage=${this.serverParams.perPage}&search=${this.serverParams.columnFilters.searchTerm}`)
        .then(response => {
          this.isLoading = false
          this.totalRecords = response.data.totalRecords
          this.rows = response.data.rows
      })
    },
    deleteEvent(id, openDate) {
      console.log(openDate)
      // Check for cookies before submit
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()
      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }

      // Check for the access
      if (!this.$can('delete', 'servolution_events')) {
        this.$swal({
          icon: 'error',
          title: 'Forbidden!',
          text: 'You are not authorized to do this action.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      } else {
        // Check if the event was before the opening date
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          // Show loading
          this.isLoading = true

          if (result.value) {
            // Delete the event
            this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/events/${id}`, { updatedAt: new Date(), deletedAt: new Date() })
              .then(() => {
                // Delete the event details
                this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/event/details?where[servolutionEventsId]=${id}`, { updatedAt: new Date(), deletedAt: new Date() })
                  .then(() => {
                    // Hide loading
                    this.isLoading = false

                    this.$swal({
                      icon: 'success',
                      title: 'Deleted!',
                      text: 'This event has been deleted.',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })

                    const eventIndex = this.rows.findIndex(event => event.id === id)
                    this.rows.splice(eventIndex, 1)
                  })
              })
          } else {
            // Hide loading
            this.isLoading = false
          }
        })
      }
      
      // else if (moment(moment().format('YYYY-MM-DD')).isBefore(moment(moment(openDate).format('YYYY-MM-DD')))) {
      //   // Check if the event was before the opening date
      //   this.$swal({
      //     title: 'Are you sure?',
      //     text: "You won't be able to revert this!",
      //     icon: 'warning',
      //     showCancelButton: true,
      //     confirmButtonText: 'Yes, delete it!',
      //     customClass: {
      //       confirmButton: 'btn btn-primary',
      //       cancelButton: 'btn btn-outline-danger ml-1',
      //     },
      //     buttonsStyling: false,
      //   }).then(result => {
      //     // Show loading
      //     this.isLoading = true

      //     if (result.value) {
      //       // Delete the event
      //       this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/events/${id}`, { updatedAt: new Date(), deletedAt: new Date() })
      //         .then(() => {
      //           // Delete the event details
      //           this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/event/details?where[servolutionEventsId]=${id}`, { updatedAt: new Date(), deletedAt: new Date() })
      //             .then(() => {
      //               // Hide loading
      //               this.isLoading = false

      //               this.$swal({
      //                 icon: 'success',
      //                 title: 'Deleted!',
      //                 text: 'This event has been deleted.',
      //                 customClass: {
      //                   confirmButton: 'btn btn-success',
      //                 },
      //               })

      //               const eventIndex = this.rows.findIndex(event => event.id === id)
      //               this.rows.splice(eventIndex, 1)
      //             })
      //         })
      //     } else {
      //       // Hide loading
      //       this.isLoading = false
      //     }
      //   })
      // } else {
      //   this.$swal({
      //     icon: 'error',
      //     title: 'Forbidden!',
      //     text: 'This event has already taken place or this event has ended.',
      //     customClass: {
      //       confirmButton: 'btn btn-success',
      //     },
      //   })
      // }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
table.vgt-table {
  border: none;
  font-size: 1rem;
}
.vgt-table thead th {
  // border-bottom: 2px solid #ebe9f1;
  outline: none;
  background-color: #f3f2f7;
}
table.vgt-table td {
  vertical-align: middle !important;
}
.vgt-wrap__footer {
  border: none;
}
</style>